import React from 'react';

import Layout from '../components/Layout';
import Title from '../components/Title';
import Message from '../components/Message';

const metaDescription =
  'Calf Hey have both MAC and PC platforms for producing artwork. We can supply low cost label artwork for you to use our for our own machines.';
const metaKeywords =
  'artwork, design, labels, print, barcodes, barcode, file formats, special products, film, bromide, typesetting, FREE, sample pack';

const Artwork = () => (
  <Layout title="Artwork" description={metaDescription} keywords={metaKeywords}>
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title
          main="Artwork & Design"
          helper="Calf Hey have a full design studio that has all the facilities necessary to produce the high quality artwork used in our printing processes."
        />

        <div className="row">
          <div className="col-md-6">
            <div className="c-content-media-1 c-bordered" style={{ minHeight: '380px' }}>
              <h2 className="c-title c-font-uppercase c-font-bold  c-center">Artwork Services</h2>
              <div className="c-content-title-3 c-title-md c-theme-border c-margin-t-30">
                <h3 className="c-theme-font c-font-uppercase">General Artwork</h3>
              </div>
              <div className="c-content-title-3 c-title-md c-theme-border">
                <h3 className="c-theme-font c-font-uppercase">Typesetting</h3>
              </div>
              <p className="c-font-normal">
                Any artwork produced by our in-house design studio for an order will receive an artwork proof to confirm
                the design and copy.
              </p>
              <p className="c-font-normal">Proofs can be either emailed or posted.</p>
              <p className="c-font-normal">
                To accompany any posted proofs we can also supply foil/material samples for colour matching.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="c-content-media-1 c-bordered" style={{ minHeight: '380px' }}>
              <h2 className="c-title c-font-uppercase c-font-bold c-center">Artwork Formats</h2>

              <div className="c-content-title-3 c-title-md c-theme-border c-margin-t-30">
                <h3 className="c-theme-font c-font-uppercase">Hotfoil</h3>
              </div>

              <p className="c-font-normal">
                Vector artwork is preferred with all text converted and supplied as a .eps, .ai or high resolution .pdf
              </p>
              <br />

              <p className="c-font-normal">
                <i className="icon-question c-font-bold" style={{ marginRight: '10px' }} />
                If you have any questions - just ask Ben at{' '}
                <a href="mailto:ben@calf-hey.com" target="_blank" rel="noopener noreferrer">
                  ben@calf-hey.com
                </a>{' '}
                or Caitlin at{' '}
                <a href="mailto:caitlin@calf-hey.com" target="_blank" rel="noopener noreferrer">
                  caitlin@calf-hey.com
                </a>
              </p>

              <div className="c-content-title-3 c-title-md c-theme-border c-margin-t-40">
                <h3 className="c-theme-font c-font-uppercase">Letterpress</h3>
              </div>

              <p className="c-font-normal">
                Vector artwork is preferred with all text converted and supplied as a .eps, .ai or high resolution .pdf
              </p>
              <br />
              <p className="c-font-normal">
                <i className="icon-question c-font-bold" style={{ marginRight: '10px' }} />
                If you have any questions - just ask Chris at{' '}
                <a href="mailto:chris@calf-hey.co.uk" target="_blank" rel="noopener noreferrer">
                  chris@calf-hey.co.uk
                </a>
              </p>

              <div className="c-content-title-3 c-title-md c-theme-border c-margin-t-40">
                <h3 className="c-theme-font c-font-uppercase">Digital</h3>
              </div>

              <p className="c-font-normal">Almost any format is accepted, but high resolution .pdf is preferred</p>
              <br />
              <p className="c-font-normal">
                <i className="icon-question c-font-bold" style={{ marginRight: '10px' }} />
                If you have any questions - just ask Chris at{' '}
                <a href="mailto:chris@calf-hey.co.uk" target="_blank" rel="noopener noreferrer">
                  chris@calf-hey.co.uk
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Message text="We do not sell print other than labels so rest assured that any artwork we produce is dealt with in the same <strong>STRICTLY CONFIDENTIAL</strong> manner as our labels." />
  </Layout>
);

export default Artwork;
